var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "twoInOne" },
      on: {
        click: function($event) {
          _vm.showKeyboard = false
        }
      }
    },
    [
      _c("div", { staticClass: "page" }, [
        _c("div", { staticClass: "storeInfo" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "rightContent" }, [
            _c(
              "div",
              { staticClass: "goodsTitle" },
              [
                _c("p", { class: _vm.moreInfo ? "twoLine" : "oneLine" }, [
                  _vm._v(_vm._s(_vm.goodsName))
                ]),
                _vm._v(" "),
                _c("Icon", {
                  staticClass: "icon",
                  staticStyle: { transition: "all 0.5s linear" },
                  attrs: {
                    type: _vm.moreInfo ? "ios-arrow-up" : "ios-arrow-down"
                  },
                  on: {
                    click: function($event) {
                      _vm.moreInfo = !_vm.moreInfo
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.goodsInfo
              ? _c(
                  "div",
                  {
                    staticClass: "goodsInfo",
                    class: _vm.moreInfo ? "threeLine" : "oneLine"
                  },
                  [
                    _c("span", [_vm._v("商品详情：")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.goodsInfo))])
                  ]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "priceInfo" }, [
          _c("p", { staticClass: "tip" }, [_vm._v("付款金额(元)：")]),
          _vm._v(" "),
          _c("div", { staticClass: "priceContent" }, [
            _c("div", { staticClass: "pattern" }, [_vm._v("￥")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "priceInput",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.timeoutKeyboard($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "coverInput" }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "text" },
                  domProps: { value: _vm.money }
                })
              ]
            ),
            _vm._v(" "),
            _vm.inputOrPay
              ? _c(
                  "div",
                  {
                    staticClass: "priceClose",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.clearMoney($event)
                      }
                    }
                  },
                  [_c("Icon", { attrs: { type: "ios-close-circle" } })],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "remarks" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.remark,
                  expression: "remark"
                }
              ],
              attrs: {
                type: "text",
                placeholder: "添加付款留言（50字以内）",
                maxlength: "50"
              },
              domProps: { value: _vm.remark },
              on: {
                blur: _vm.blur,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.remark = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btnCont", on: { click: _vm.payMoney } }, [
            _c("button", [_vm._v("立即支付")])
          ])
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "standBy" }, [
          _c("span", [_vm._v("由")]),
          _vm._v(" "),
          _c("img", {
            style: _vm.isAndroid ? "margin-top:-0.1rem" : "",
            attrs: {
              src: require("../../assets/image/twoInOne/logo-twoInOne.png"),
              alt: ""
            }
          }),
          _vm._v(" "),
          _c("span", [_vm._v("提供技术支持")])
        ])
      ]),
      _vm._v(" "),
      _c("keyboard", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showKeyboard && _vm.inputOrPay,
            expression: "showKeyboard && inputOrPay"
          }
        ],
        ref: "keyBoard",
        attrs: { payEven: _vm.payEvent },
        on: { keyBoardVal: _vm.keyboardVal, keyBoardPay: _vm.payMoney }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.modalShow,
              expression: "modalShow"
            }
          ],
          staticClass: "modalMsg",
          on: {
            click: function($event) {
              _vm.modalShow = false
            }
          }
        },
        [
          _c("div", { staticClass: "tipMsg" }, [
            _c("div", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.tipMsg))]),
            _vm._v(" "),
            _c("div", { staticClass: "btn" }, [_vm._v("确定")])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.payComplete
        ? _c("div", { staticClass: "payEndStatus" }, [
            _c("div", { staticClass: "endContent" }, [
              _vm.payEndStatus
                ? _c("img", {
                    attrs: {
                      src: require("../../assets/image/twoInOne/pay_success.png"),
                      alt: ""
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.payEndStatus
                ? _c("img", {
                    attrs: {
                      src: require("../../assets/image/twoInOne/pay_defult.png"),
                      alt: ""
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("p", [_vm._v("支付成功")])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "leftImg" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/image/twoInOne/shopmall.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payTips" }, [
      _c("span", { staticClass: "point" }),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "友情提示：请您核对以上金额、收款商户等信息与交易一致；经您确认支付的款项，支付机构无法追回亦无赔偿义务。"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }