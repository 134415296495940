import spaRoutes from './spaRoutes.js';
import twoInOne from '../components/twoInOne/twoInOne.vue';
import menuNavigator from './menuNavigator';

const routes = [
  {
    path: '/',
    component: () => import('../components/MainContain'),
    redirect: '/lightPosView',
    children: menuNavigator()
      .reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue.submenu || []),
        []
      )
  }
].concat(spaRoutes);

export default routes;
