const spaRoutes = [
  {
    label: '收钱吧轻POS',
    name: 'lightPos',
    path: '/lightPos',
    component: () => import('../components/lightPos/LightPos')
  },
  {
    label: '支付二合一',
    name: 'twoInOne',
    path: '/twoInOne',
    component: () => import('../components/twoInOne/twoInOne')
  },
  {
    label: '支付结果',
    name: 'payResult',
    path: '/payResult',
    component: () => import('../components/twoInOne/payResult')
  }
];

export default spaRoutes;
