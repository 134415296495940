<template>
  <div id="twoInOne" @click="showKeyboard = false">
    <div class="page">
      <div class="storeInfo">
        <!--{{timesNum}}-->
        <!--{{testStatus}}-->
        <div class="leftImg">
          <img src="../../assets/image/twoInOne/shopmall.png" alt="">
        </div>
        <div class="rightContent">
          <div class="goodsTitle">
            <p :class="moreInfo ? 'twoLine' : 'oneLine' ">{{goodsName}}</p>
            <Icon style="transition: all 0.5s linear;" @click="moreInfo = !moreInfo" class="icon" :type="moreInfo ? 'ios-arrow-up' : 'ios-arrow-down'" />
          </div>
          <div v-if="goodsInfo" :class="moreInfo ? 'threeLine' : 'oneLine' " class="goodsInfo">
            <span>商品详情：</span>
            <span>{{goodsInfo}}</span>
          </div>
        </div>
      </div>
      <div class="priceInfo" >
        <p class="tip">付款金额(元)：</p>
        <div class="priceContent">
          <div class="pattern">￥</div>
          <div class="priceInput"  @click.stop="timeoutKeyboard">
            <div class="coverInput"></div>
            <input type="text" :value="money">
          </div>
          <div v-if="inputOrPay" class="priceClose" @click.stop="clearMoney">
            <Icon type="ios-close-circle" />
          </div>
        </div>
        <div class="remarks">
          <input v-model="remark" @blur="blur" type="text" placeholder="添加付款留言（50字以内）" maxlength="50">
        </div>
        <div class="btnCont" @click="payMoney">
          <button>立即支付</button>
        </div>
      </div>
      <div class="payTips">
        <span class="point"></span>
        <span>友情提示：请您核对以上金额、收款商户等信息与交易一致；经您确认支付的款项，支付机构无法追回亦无赔偿义务。</span>
      </div>
      <div class="standBy">
        <span>由</span>
        <img :style="isAndroid ? 'margin-top:-0.1rem' : ''" src="../../assets/image/twoInOne/logo-twoInOne.png" alt="">
        <span>提供技术支持</span>
      </div>
    </div>
    <keyboard v-show="showKeyboard && inputOrPay" :payEven="payEvent" ref="keyBoard" @keyBoardVal="keyboardVal" @keyBoardPay="payMoney"></keyboard>
    <div class="modalMsg" v-show="modalShow" @click="modalShow = false">
      <div class="tipMsg">
        <div class="msg">{{tipMsg}}</div>
        <div class="btn">确定</div>
      </div>
    </div>
    <div class="payEndStatus" v-if="payComplete">
      <div class="endContent">
        <img v-if="payEndStatus" src="../../assets/image/twoInOne/pay_success.png" alt="">
        <img v-if="!payEndStatus" src="../../assets/image/twoInOne/pay_defult.png" alt="">
        <p>支付成功</p>
<!--        <div @click="closePay">完成</div>-->
      </div>
    </div>
  </div>
</template>

<script>
  // import rem from '../../constants/rem';
  import keyboard from './keyboard';
  import {urlFormData} from '../../constants/apis';

  export default {
    name: 'twoInOne',
    components: {
      keyboard
    },
    data(){
      return {
        baseUrl: 'https://qrcode.pay2.piplus.cn', // 后端接口地址
        // baseUrl: window.location.protocol+"//"+document.domain, // 后端接口地址
        payEvent: 'others',
        goodsName: '', // 标题
        goodsInfo: '', // 详情信息
        moreInfo: false, // 显示更多信息
        money: '0.00',
        remark: '', // 备注
        token: null, // token
        customerId: '', // 平台商户号
        timestamp: '', // 时间戳
        tradeNo: '', // 查询订单号
        showKeyboard: true, // 显示金额输入
        inputOrPay: false, // 输入金额还是显示金额，默认显示金额
        times: null, // 定时器查询订单状态
        timesNum: 0, // 定时器查询次数
        disabledPay: true, // 不可点击支付
        payComplete: false, // 支付完成
        payEndStatus: false, // 支付完成 是否成功显示的图片
        modalShow: false, // 提示消息模态框
        tipMsg: '这里是错误提示!!', // 错误框消息
        testStatus:'无'
      };
    },
    beforeCreate() {
      if (/MicroMessenger/.test(window.navigator.userAgent)) {
        if (window.location.href.indexOf('code=') < 0 ) {
          setTimeout(() => {
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5c3e5f3ee1a9ccda&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
            window.location.replace(url);
            // window.location.href = url;
          });
        }
        // 微信
      }
    },
    created() {
      this.payEventPro();
      let url = this.baseUrl + '/pay/get_web_param';
      let params = {
        key: this.$route.query.key
      };
      if (!params.key) {
        this.messageTips('当前二维码缺少参数，请重新生成！');
        this.disabledPay = false;
      } else {
        this.API.get(url, { params }).then((res) => {
          if (res.data.status_code === 'SUCCESS') {
            this.inputOrPay = res.data.response.param.static_qrcode !== 'N';
            this.goodsName = res.data.response.param.body;
            this.money = res.data.response.param.total_amount/100 || '0.00';
            this.customerId = res.data.response.param.customer_id;
            this.tradeNo = res.data.response.param.out_trade_no;
            this.timestamp = res.data.response.timestamp;
            this.response = res.data.response || '';
            this.token = res.data.response.token;
          } else {
            this.messageTips(res.data.err_msg || '获取参数错误:参数已不存在,请返回重试！');
            this.disabledPay = false;
          }
        });
      }

      document.getElementsByTagName('title')[0].innerText = '向商家付款';

    },
    mounted() {
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.goBack, false);
      };
      // 页面挂载后可能停止定时器 重新启动
      // let vm = this;
      // document.addEventListener('visibilitychange', function() {
      //   if (document.hidden === true) {
      //     console.log('挂载了');
      //   } else {
      //     // alert('挂载回来了');
      //     clearInterval(vm.times);
      //     vm.timesNum = 0;
      //     vm.times = setInterval(() => {
      //       vm.orderquery();
      //       vm.timesNum++;
      //     },3000);
      //   }
      // });
      console.log(this.payEvent);
    },
    methods: {
      goBack(){
        if (this.payEvent === 'WEIXINPAY') {
          WeixinJSBridge.call('closeWindow');
        }
      },
      payEventPro() { // 支付环境查询
        this.isAndroid = /Android/.test(window.navigator.userAgent);
        // 判断微信还是支付宝
        if (/MicroMessenger/.test(window.navigator.userAgent)) {
          console.log('当前使用的是微信浏览器！');
          // 微信
          this.payEvent = 'WEIXINPAY';
        } else if (/AlipayClient/.test(window.navigator.userAgent)) {
          console.log('当前使用的是支付宝览器！');
          // 支付宝
          this.payEvent = 'ALIPAY';
        } else {
          console.log('当前使用的是其他览器！');
          this.payEvent = 'others';
        }
      },
      keyboardVal(val) { // 自定义键盘操作
        if (val) {
          this.money = val;
        } else {
          this.money = '0.00';
        }
      },
      clearMoney() { // 清除输入金额
        this.$refs.keyBoard.clearVal();
        this.money = '0.00';
        this.timeoutKeyboard();
      },
      timeoutKeyboard() { // 键盘输入弹起优化 延迟显示
        window.scroll(0, 0);
        setTimeout(()=>{
          this.showKeyboard = true;
          window.scroll(0, 0);
        },50);
      },
      blur() { // ios情况，input输入弹起无法弹回
        window.scroll(0, 0);
      },
      messageTips(msg) { // 错误提示
        this.tipMsg = msg;
        this.modalShow = true;
      },
      payBefore(resolve) {
        if (!this.response) {
          this.messageTips('参数不完整，请重新扫码！');
          this.disabledPay = false;
          return;
        }
        if (!this.money || Number(this.money) ===0){
          this.messageTips('金额不能为空！');
          return;
        }
        // 支付全屏支付中提示
        this.$Spin.show({
          render: (h) => {
            return h('div', {class:  'mytest'}, [
              h('Icon', {
                class: ['demo-spin-icon-load', 'mytest'],
                props: {
                  type: 'ios-loading'
                }
              }),
              h('div', '支付中...')
            ]);
          }
        });
        this.response.remark = this.remark;
        this.response.param.precreatetype = this.payEvent;
        this.response.param.total_amount = this.money*100;


        // function getUrlVars() { // 地址中所有?&拼接城对象
        //   let vars = {};
        //   let str = window.location.href;
        //   let parts = str.replace(/[?&]+([^=&]+)=([^?|&]*)/gi, function (m, key, value) {
        //     vars[key] = value;
        //   });
        //   return vars;
        // }

        function GetQueryString(name) {
          var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
          var r = window.location.search.substr(1).match(reg);
          if(r!=null)return  decodeURIComponent(r[2].replace(/\+/g,'%20')); return null;
        }
        let code = GetQueryString('code') || '';
        this.response.param.code = code;
        let params = this.response;
        // alert(JSON.stringify(params));
        this.API.post( `${this.baseUrl}/pay/qrcodepay`, urlFormData(params) ).then((res) => {
          // alert(JSON.stringify(res));
          if (res.data.status_code === 'SUCCESS' && res.data.response.result_code === 'SUCCESS') {
            resolve(res.data);
          } else {
            this.$Spin.hide();
            if (res.data.response && res.data.response.err_code === 'ORDER_REPEAT') {
              this.messageTips('订单号重复，请重新扫描二维码！');
            } else {
              this.messageTips( res.data.err_msg || res.data.response.err_msg || '支付失败，请重新扫描二维码或者使用，支付宝微信支付！');
            }
          }
        });
      },
      payMoney() { // 支付
        // 参数异常处理
        if (!this.disabledPay) {
          this.messageTips('参数异常，请更换二维码重新扫描！');
          return;
        }

        if (this.payEvent === 'WEIXINPAY') {
          this.payBefore((res) => {
            let vm = this;
            // 微信jsapi支付
            function onBridgeReady(){
              // alert(JSON.stringify(res));
              WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                  appId:  res.response.app_id, //公众号名称，由商户传入
                  timeStamp: res.response.time_stamp, //时间戳，自1970年以来的秒数
                  nonceStr: res.response.nonce_str, //随机串
                  package: res.response.wx_package,
                  signType: res.response.sign_type, //微信签名方式：
                  paySign: res.response.pay_sign //微信签名
                },
                (resY) => {
                  // alert(JSON.stringify(resY));
                  if (resY.err_msg === 'get_brand_wcpay_request:ok' ){
                    setTimeout(() => {
                      vm.orderquery();
                    },1000);
                    clearInterval(vm.times);
                    vm.times = setInterval(() => {
                      vm.orderquery();
                      vm.timesNum++;
                    },3000);
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  } else {
                    vm.afterPay();
                  }
                }
              );
            }



            if (typeof WeixinJSBridge === 'undefined'){
              if ( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
              } else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
              }
            } else {
              onBridgeReady();
            }
          });
        } else {
          let protoOrAli = true;
          // H5支付浏览器支付 只支持支付宝支付
          if (this.payEvent !== 'ALIPAY') {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('该浏览器暂只支持支付宝支付！')) {
              this.payEvent = 'ALIPAY';
              protoOrAli = false;
            } else {
              return;
            }
          }
          this.payBefore((res) => {


            // const div = document.createElement('div');
            // div.innerHTML = res.response.qr_code; // html code
            // document.body.appendChild(div);
            // document.getElementsByName('punchout_form')[0].submit();



            let aliUrl = res.response.qr_code + '?';
            for (let key in res.response) {
              if ( key === 'qr_code') {
                break;
              } else {
                aliUrl = aliUrl + key + '=' + res.response[key] +'&';
              }
            }
            // alert(aliUrl);
            this.orderquery();
            clearInterval(this.times);
            this.timesNum = 0;
            this.times = setInterval(() => {
              this.orderquery();
              this.timesNum++;
            },3000);
            if (protoOrAli) {
              AlipayJSBridge.call('pushWindow', {
                url: aliUrl,
                param: {
                  readTitle: true,
                  showOptionMenu: false
                }
              });
            } else {
              window.location.href = aliUrl;
            }
            // window.location.href = aliUrl;
          });

        }
      },
      orderquery() { // 查询订单号状态
        if (this.timesNum < 30) {
          let url = this.baseUrl + '/pay/orderquery';
          let params = {
            timestamp: this.timestamp,
            token: this.token,
            param : {
              out_trade_no: this.tradeNo,
              customer_id: this.customerId
            }
          };
          // alert(JSON.stringify(params))
          this.API.post(url, urlFormData(params)).then((res) => {
            // alert(JSON.stringify(res));
            if (res.data && res.data.status_code === 'SUCCESS') {
              if (res.data.response.trade_state === 'TRADE_SUCCESS' || res.data.response.trade_state === 'TRADE_FINISHED') {
                // 支付成功
                this.afterPay(true);
              } else if (res.data.response.trade_state === 'TRADE_REFUNDED' || res.data.response.trade_state === 'TRADE_CLOSED'
                      || res.data.response.trade_state === 'TRADE_REVOKED ' || res.data.response.trade_state === 'TRADE_FINISHED ') {
                // 支付失败状态
                this.afterPay(false);
              }
            } else {
              this.messageTips('查询出错，请重新扫码支付！');
              this.afterPay();
            }
          });
        } else {
          this.afterPay();
        }

      },
      afterPay(status) { // 支付完成后的操作
        clearInterval(this.times);
        this.timesNum = 0;
        this.$Spin.hide();
        if (status !== undefined) {
          this.payComplete = true;
          this.payEndStatus = status;
        }
      },
      closePay() {
        alert(2);
        // AlipayJSBridge.call('popWindow');
      }
    }
  };
</script>

<style lang="less" type="text/less">
  #twoInOne {
    width: 100%;
    height: 100%;
    /*padding: 0.24rem 0.16rem 0.8rem 0.16rem;*/
    /*font-size: 0.28rem;*/
    background: #F6F6F6;
    position: relative;
    .page {
      width: 100%;
      min-height: 100%;
      padding: 0.24rem 0.16rem 0.8rem 0.16rem;
      font-size: 0.28rem;
      /*background: #F6F6F6;*/
      position: relative;
      .storeInfo {
        padding: 0.32rem;
        background: #fff;
        border-radius: 5px;
        display: flex;
        .leftImg {
          margin-right: 0.1rem;
          img {
            width: 1rem;
          }
        }
        .rightContent {
          flex: 1 1 auto;
          .goodsTitle {
            display: flex;
            font-size: 0.32rem;
            margin-top: 0.04rem;
            margin-bottom: 0.1rem;
            p {
              width: 100%;
              color: #191F25;
              /*line-height: 0.44rem;*/
            }
            .icon {
              line-height: 0.5rem;
              margin-left: 0.1rem;
            }
          }
          .goodsInfo {
            font-size: 0.26rem;
            color: #9F9F9F;
            /*line-height: 0.36rem;*/
            span {

            }
          }
        }
      }
      .priceInfo {
        margin:0.24rem 0;
        padding: 0.8rem 0.32rem;
        background: #fff;
        border-radius: 5px;
        .tip {
          font-size: 0.28rem;
          color: #4E4E4E;
        }
        .priceContent {
          padding: 0.24rem 0;
          font-size: 0.72rem;
          color: #191F25;
          display: flex;
          align-items: center;
          border-radius: 10px;
          .pattern {
            margin-right: 0.1rem;
          }
          .priceInput {
            flex: 1 1 auto;
            position: relative;
            input {
              font-weight: bold;
              width: 100%;
              border: none;
              background: #fff;
              position: relative;
            }
            div.coverInput {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 9;
            }
          }
          /*.priceInput:after {*/
          /*  display: block;*/
          /*  content: '';*/
          /*  width: 100%;*/
          /*  height: 100%;*/
          /*  position: absolute;*/
          /*  left: 0;*/
          /*  top: 0;*/
          /*  z-index: 99;*/
          /*}*/
          .priceClose {
            flex: 0 0 auto;
            width: 0.4rem;
            height: 0.4rem;
            padding: 0.05rem;
            font-size: 0.3rem;
            margin-left: 0.18rem;
            i {
              vertical-align: top;
              color: #9F9F9F;
            }
          }
        }
        .remarks {
          height: 1rem;
          padding: 0.28rem 0;
          border-top: 1px solid rgba(25, 31, 37, 0.12);
          border-bottom: 1px solid rgba(25, 31, 37, 0.12);
          input {
            width: 100%;
            color: #4E4E4E;
            font-size: 0.28rem;
            border: none;
          }
        }
        .btnCont {
          margin-top: 0.64rem;
          width: 100%;
          button {
            width: 100%;
            height: 0.84rem;
            line-height: 0.84rem;
            background-image: linear-gradient(to right, #7A9EFF , #5481FB);
            color: #fff;
            font-size: 0.32rem;
            text-align: center;
            border: none;
            border-radius: 50px;
          }
          span {
            vertical-align: middle;
          }
        }
      }
      .payTips {
        margin-top: 0.32rem;
        margin-bottom: 0.5rem;
        font-size: 0.28rem;
        color: #9F9F9F;
        padding: 0 0.16rem;
        background: #F6F6F6;
        .point {
          display: inline-block;
          width: 0.16rem;
          height: 0.16rem;
          border-radius: 50%;
          background: #5481FB;
        }
      }
      .standBy {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        height: 0.74rem;
        font-size: 0.28rem;
        color: #9F9F9F;
        span {
          vertical-align: middle;
        }
        img {
          vertical-align: middle;
          height: 0.32rem;
        }
      }
    }


    .modalMsg {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0,0,0,.5);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;
      z-index: 99;
      .tipMsg {
        min-width: 60%;
        background: rgb(255,255,255);
        border-radius: 10px;
        /*padding: 0.32rem 0 0.2rem 0;*/
        font-size: 0.32rem;
        color: #191F25;
        text-align: center;
        .msg {
          padding: 0.42rem 0.62rem;
          border-bottom: 1px solid rgba(25, 31, 37, 0.12);
        }
        .btn {
          font-weight: bold;
          padding: 0.2rem 0;
          color: #0974F2;
        }
      }
    }
    .payEndStatus {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #F6F6F6;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;
      z-index: 99;
      .endContent {
        text-align: center;
        margin-top: -20%;
        img {
          width: 4rem;
          height: 4rem;
          margin: auto;
        }
        p {
          font-size: 0.48rem;
          margin: 0.48rem auto 1.2rem auto;
        }
        div {
          width: 6.25rem;
          padding: 0.2rem 0;
          font-size: 0.32rem;
          color: #fff;
          background-image: linear-gradient(to right, #7A9EFF, #5481FB);
          border-radius: 50px;
        }
      }
    }
  }

  .goodsTitle p.oneLine {
    max-height: 0.44rem;
  }
  .goodsTitle p.twoLine {
    max-height: 0.88rem;
  }
  .goodsInfo.oneLine {
    max-height: 0.36rem;
  }
  .goodsInfo.twoLine {
    max-height: 0.72rem;
  }
  .goodsInfo.threeLine {
    max-height: 1.08rem;
  }
  .oneLine {
    /*text-overflow:ellipsis;*/
    /*white-space: nowrap;*/
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;

    transition: max-height 0.5s linear;
  }
  .twoLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    transition: max-height 0.5s linear;
  }
  .threeLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    transition: max-height 0.5s linear;
  }
  .burgeon-spin-fix {
    background-color: rgba(0, 0, 0, 0.3)!important;
  }
  .mytest {
    font-size: 0.48rem;
    color: #fff;
    div {
      font-size: 0.3rem;
    }
  }
</style>
