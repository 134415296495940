export default (instance = null) => [
  {
    label: '机具',
    icon: '',
    submenu: [
      {
        label: '收钱吧轻POS',
        path: '/lightPosView',
        component: () => import('../components/menuViews/LightPos')
      }
    ]
  },
  {
    label: '二合一支付',
    icon: '',
    submenu: [
      {
        label: '二合一支付',
        path: '/twoInOneView',
        component: () => import('../components/twoInOne/twoInOne')
      },
      {
        label: '支付结果',
        path: '/payResultView',
        component: () => import('../components/twoInOne/payResult')
      }
    ]
  }
];
