<template>
    <div id="keyboard">
        <div class="numberKey">
            <div v-for="(item, index) in numArr" @click.stop="upVal(item, index)"
                 :class="[item == 0 ? 'zero' : '' , actionBg === index ? 'act' : '' ]">
                {{item}}
            </div>
        </div>
        <div class="operation">
            <div @click.stop="delVal" class="del" :class="actionBg === 'x' ? 'act' : '' ">
                <img src="../../assets/image/twoInOne/clean.png" alt="">
            </div>
            <div @click.stop="keyConfirm" class="ok"
                 :class="[ payEven == 'weixin' ? 'wxBg' : 'aliBg',actionBg === 'k' ? 'act' : '']">支付
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'keyboard',
    props: {
      payEven: {
        type: String,
        required: true,
        default: 'alipay'
      }
    },
    data() {
      return {
        numArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '.'],
        actionBg: '',
        price: ''
      };
    },
    methods: {
      actClass(index) { // 按键点击灰色视觉效果
        this.actionBg = index;
        setTimeout(() => {
          this.actionBg = '';
        }, 200);
      },
      upVal(item, index) { // 数字输入
        this.actClass(index);
        if (item === '.' && this.price.indexOf('.') > -1) {

        } else {
          let pointIndex = this.price.indexOf('.');
          if (pointIndex > -1 && this.price.length - pointIndex > 2) {

          } else {
            if (item === 0 && this.price === '0') {
              return;
            }
            this.price = (this.price == '' && item == '.' ? '0' : this.price) + item;
          }
        }
        // console.log(this.price)
      },
      delVal() { // 删除按钮
        this.actClass('x');
        this.price = this.price.slice(0, this.price.length - 1);
        // console.log(this.price)
      },
      keyConfirm() { // 支付
        this.actClass('k');
        this.$emit('keyBoardPay');
      },
      clearVal() {
        this.price = '';
      }
    },
    watch: {
      price(val, oldVal) {
        console.log(val);
        console.log(oldVal);
        if (val != oldVal) {
          this.$emit('keyBoardVal', val);
        }
      }
    }
  };
</script>

<style lang="less" type="text/less">
    #keyboard {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        border-top: 1px solid #EAEAEA;
        background: #fff;
        font-size: 0.4rem;

        /*键盘长按禁止复制*/
        -webkit-touch-callout:none;
        -webkit-user-select:none;
        -khtml-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        /**/

        .numberKey {
            flex: 3;
            display: flex;
            flex-wrap: wrap;

            > div {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                cursor: pointer;
                /*padding: 0.25rem 0;*/
                text-align: center;
                border-right: 1px solid #EAEAEA;
                border-bottom: 1px solid #EAEAEA;
                flex: 0 0 33.33%;
                height: 1rem;
                line-height: 1rem;
            }

            > div.zero {
                flex: 0 0 66.66%;
            }

            > div.act {
                background: #eee;
            }
        }

        .operation {
            flex: 1;
            display: flex;
            flex-direction: column;

            > div {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            > div.del {
                height: 1rem;
                /*padding: 0.25rem 0;*/
                border-bottom: 1px solid #EAEAEA;

                img {
                    width: 0.5rem;
                }
            }

            > div.del.act {
                background: #eee;
            }

            > div.ok {
                flex: 3;
                font-size: 0.4rem;
                color: #fff;
            }

            .wxBg {
                background: #00C453;
            }

            .aliBg {
                background: #0F77FE;
            }

            > div.ok.wxBg.act {
                background: #06A94B;
            }

            > div.ok.aliBg.act {
                background: #025DD3;
            }
        }
    }
</style>
