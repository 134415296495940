import Vue from 'vue';
import BurgeonUi from 'burgeon-ui';

import store from './src/constants/store';
import App from './src/App';
import API from './src/constants/apis';
import 'burgeon-ui/dist/styles/burgeon-ui.css';
import './src/assets/theme/theme.less';

// ios移动端点击300ms延迟解决方案
import fastclick from 'fastclick';
// Vue.config.productionTip = false;
fastclick.attach(document.body);
// ------

Vue.use(BurgeonUi,{
  transfer:true
});

Vue.prototype.API = API;

Vue.prototype.$store = store;


const applicationId = 'XCollectorApplication';

const createRootDom = () => {
  const root = document.createElement('div');
  root.setAttribute('id', applicationId);
  document.body.appendChild(root);
};

const initializeApplication = () => {
  createRootDom();
  global.vm = new Vue({
    store,
    render: createElement => createElement(App)
  }).$mount(`#${applicationId}`);
};

initializeApplication();

