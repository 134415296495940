var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "keyboard" } }, [
    _c(
      "div",
      { staticClass: "numberKey" },
      _vm._l(_vm.numArr, function(item, index) {
        return _c(
          "div",
          {
            class: [
              item == 0 ? "zero" : "",
              _vm.actionBg === index ? "act" : ""
            ],
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.upVal(item, index)
              }
            }
          },
          [_vm._v("\n            " + _vm._s(item) + "\n        ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "operation" }, [
      _c(
        "div",
        {
          staticClass: "del",
          class: _vm.actionBg === "x" ? "act" : "",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.delVal($event)
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("../../assets/image/twoInOne/clean.png"),
              alt: ""
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ok",
          class: [
            _vm.payEven == "weixin" ? "wxBg" : "aliBg",
            _vm.actionBg === "k" ? "act" : ""
          ],
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.keyConfirm($event)
            }
          }
        },
        [_vm._v("支付\n        ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }