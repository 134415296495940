import axios from 'axios';

axios.interceptors.response.use((response) => {
  return response;
}, (err) => {
  err.data = {};
  try {
    if (err && err.response) {
      switch (err.response.status) {
      case 400: err.data.err_msg = '请求错误(400)' ; break;
      case 401: err.data.err_msg = '未授权，请重新登录(401)';break;
      case 403: err.data.err_msg = '拒绝访问(403)'; break;
      case 404: err.data.err_msg = '请求出错(404)'; break;
      case 408: err.data.err_msg = '请求超时(408)'; break;
      case 500: err.data.err_msg = '服务器错误(500)'; break;
      case 501: err.data.err_msg = '服务未实现(501)'; break;
      case 502: err.data.err_msg = '网络错误(502)'; break;
      case 503: err.data.err_msg = '服务不可用(503)'; break;
      case 504: err.data.err_msg = '网络超时(504)'; break;
      case 505: err.data.err_msg = 'HTTP版本不受支持(505)'; break;
      default:
        err.data.err_msg = `连接出错(${err.response.status})!`;
      }
    } else {
      err.data.err_msg = '连接服务器失败!';
    }
  } catch (e){
    err.data.err_msg = '连接服务器失败!';
  }
  return err;
  // return Promise.reject(err);
});
// 当post请求content-Type: application/x-www-form-urlencoded时，需要将JSON参赛转换成如下函数输入的形式。
export const urlSearchParams = (data) => {
  const params = new URLSearchParams();
  Object.keys(data).forEach((key) => {
    const dataType = Object.prototype.toString.call(data[key]);
    if (dataType === '[object Object]' || dataType === '[object Array]') {
      data[key] = JSON.stringify(data[key]);
    }
    params.append(key, data[key]);
  });
  return params;
};

export const urlFormData = (data) => {
  return encodeURIComponent(JSON.stringify(data));
};

export default axios;
