<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<script>
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import routes from './constants/routes';
import 'babel-polyfill';
import store from './constants/store';
import rem from './constants/rem';
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const router = new VueRouter({
  routes
});



export default {
  name: 'App',
  store,
  router
};
</script>

<style lang="less">
  /*ios移动端 300ms延迟使用fastclick的错误处理*/
  * { touch-action: manipulation }
  .app {
    width: 100%;
    height: 100%;
  }
  .burgeon-spin-fullscreen{
    .demo-spin-icon-load{
      animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
      from { transform: rotate(0deg);}
      50%  { transform: rotate(180deg);}
      to   { transform: rotate(360deg);}
    }
  }
</style>
